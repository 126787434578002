import React, { useRef } from "react"
import { graphql } from "gatsby"

import Img from "gatsby-image"

import Layout from "../components/Layout"
import Contact from "../components/Contact"
import styled from "styled-components"

import { Link } from "gatsby"
const Section = styled.section`
  align-self: center;

  width: 90%;

  @media (min-width: 576px) {
    width: 70%;
  }

  max-width: 1200px;

  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);

  justify-content: center;
  align-items: center;

  grid-gap: 2.5em;
  margin-bottom: 10em;
`
const SectionWithHeader = styled(Section)`
  & > *:first-child {
    grid-column: span ${props => props.columns};
    text-align: center;
  }
`
const HeroSection = styled(Section)`
  //  width: 70%;
  width: 90%;

  @media (min-width: 576px) {
    width: 70%;
  }

  height: 100%;

  position: relative;

  display: grid;
  grid-template-columns: 1fr 2fr !important;

  .front-img {
    position: absolute;
    width: 100%;
    height: 100%;
    /* margin: 0px 20% 0px 20%; */
    overflow: hidden;
    z-index: 1 !important;
  }

  .bg-img {
    position: absolute;
    width: 100vw;
    height: 100%;
    margin-left: calc(-1 * (100vw - 100%) / 2);
    z-index: -1 !important;

    overflow: hidden;
    .gatsby-image-wrapper {
      filter: blur(16px);
    }
  }

  .gatsby-image-wrapper {
    height: 100%;
  }

  & > * {
    z-index: 2;
  }

  .title {
    color: white;
  }
`

const SectionTitle = styled.div`
  font-size: 2em;
  font-family: monument;
  text-align: center;
  text-transform: uppercase;

  padding-bottom: 2.5em;
`
const Text = styled.div`
  font-size: 1em;
  line-height: 1.25;
  font-family: monument-light;
  text-align: center;

  padding-right: 2.5em;
  padding-left: 2.5em;
  padding-bottom: 2.5em;

  ${({ padding }) => `padding: ${padding};`}
`

const MiddleSizeText = styled.div`
  font-size: 1.5em;
  line-height: 1.25;
  font-family: monument-light;
  text-align: center;

  ${props => (props.padding ? `padding: ${props.padding};` : "")}
`

const SubTitle = styled(Text)`
  font-size: 1.25em;
  font-family: monument-bold;
  text-transform: uppercase;

  // Extra small devices (portrait phones, less than 576px)
  color: white;
  //color: black;

  padding-top: 40%;
  align-self: flex-start;

  @media (min-width: 576px) {
    padding-top: 30%;
    align-self: flex-start;
  }
`
const SideImage = styled.div`
  border-radius: 2em;
  overflow: hidden;
`
const SideTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const CardText = styled(Text)`
  padding-top: 2.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  align-self: flex-start;
`
const CardImg = styled.div`
  width: 10em;
  height: 10em;
  overflow: hidden;
  margin: auto;
`

const CTAWrapper = styled.div`
  display: flex;
  justify-content: ${({ justify }) => "" + justify};
  align-items: center;
`
const CTAButtonStyle = styled.button`
  margin: 0px 1em 0px 1em;
  padding: 1.25em 2.5em 1.25em 2.5em;
  border-radius: 10em; // just round
  text-transform: uppercase;
  font-size: 1.25em;
  font-family: monument;

  border: none;

  box-shadow: 0.3em 0.3em 0.3em #ccc;
  ${({ noShadow }) => (noShadow ? `box-shadow: none;` : "")}
  background: rgb(245,215,160);
  background: linear-gradient(
    133deg,
    rgba(245, 215, 160, 1) 30%,
    rgba(240, 185, 175, 1) 100%
  );

  transition: 0.5s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  a {
    text-decoration: none;
    color: black;
  }
`

const CTAButton = ({
  noShadow = false,
  justify = "center",
  style = {},
  onClick,
  children,
}) => (
  <CTAWrapper onClick={onClick} justify={justify} style={style}>
    <CTAButtonStyle noShadow={noShadow}>{children}</CTAButtonStyle>
  </CTAWrapper>
)

const ColorAccordeon = styled.div`
  display: flex;
  flex-direction: column;

  & > * :first-child {
    border-radius: 1em 1em 0px 0px;
  }

  & > *:last-child {
    border-radius: 0px 0px 1em 1em;
  }

  & > *:nth-child(1) {
    background-color: rgb(${({ nItems, R, G, B }) => `${R},${G},${B}`});
  }

  & > *:nth-child(2) {
    background-color: rgb(
      ${({ nItems, R, G, B }) =>
        `${R + (255 - R) * (1 / nItems)}, ${G + (255 - G) * (1 / nItems)}, ${
          B + (255 - B) * (1 / nItems)
        }`}
    );
  }
  & > *:nth-child(3) {
    background-color: rgb(
      ${({ nItems, R, G, B }) =>
        `${R + (255 - R) * (2 / nItems)}, ${G + (255 - G) * (2 / nItems)}, ${
          B + (255 - B) * (2 / nItems)
        }`}
    );
  }
  & > *:nth-child(4) {
    background-color: rgb(
      ${({ nItems, R, G, B }) =>
        `${R + (255 - R) * (3 / nItems)}, ${G + (255 - G) * (3 / nItems)}, ${
          B + (255 - B) * (3 / nItems)
        }`}
    );
  }
  & > *:nth-child(5) {
    background-color: rgb(
      ${({ nItems, R, G, B }) =>
        `${R + (255 - R) * (4 / nItems)}, ${G + (255 - G) * (4 / nItems)}, ${
          B + (255 - B) * (4 / nItems)
        }`}
    );
  }
  & > *:nth-child(6) {
    background-color: rgb(
      ${({ nItems, R, G, B }) =>
        `${R + (255 - R) * (5 / nItems)}, ${G + (255 - G) * (5 / nItems)}, ${
          B + (255 - B) * (5 / nItems)
        }`}
    );
  }
  & > *:nth-child(7) {
    background-color: rgb(
      ${({ nItems, R, G, B }) =>
        `${R + (255 - R) * (6 / nItems)}, ${G + (255 - G) * (6 / nItems)}, ${
          B + (255 - B) * (6 / nItems)
        }`}
    );
  }
`

const Testimonial = styled(Text)`
  border-radius: 1em;
  background: rgb(241, 145, 146);
  background: linear-gradient(
    133deg,
    rgba(245, 215, 160, 1) 30%,
    rgba(240, 185, 175, 1) 100%
  );
  height: 100%;
  text-align: left;
  padding: 1.25em;
`

export default ({ data }) => {
  const letsMeetRef = useRef(null)

  return (
    <Layout
      title={
        <div>
          BARSKA
          <br />
          PHOTO
          <br />
          STORIES
        </div>
      }
      fluid={true}
    >
      <HeroSection columns={1} style={{ marginBottom: "6em" }}>
        {/* <div className="bg-img">
          <Img fluid={data.hero1.childImageSharp.fluid} />
        </div> */}
        <div className="front-img">
          <Img fluid={data.hero1.childImageSharp.fluid} />
        </div>

        <SubTitle>
          Hi, I'm Dominika
          <br />
          documentary & branding
          <br />
          photographer
        </SubTitle>

        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            paddingBottom: "10%",
          }}
        >
          <SectionTitle
            className="title"
            style={{
              textAlign: "right",
              paddingRight: "15%",
              paddingTop: "3em",
              paddingBottom: "1em",
            }}
          >
            I am here
            <br /> to tell
            <br />
            your story
          </SectionTitle>
          <CTAButton
            noShadow
            justify="flex-end"
            style={{ paddingRight: "15%" }}
            onClick={() => letsMeetRef.current.scrollIntoView()}
          >
            Let's Meet
          </CTAButton>
        </div>
      </HeroSection>
      <Section column={1}>
        <MiddleSizeText>
          Are you an artist or a soulful brand owner? I will make a branding or
          event photo session for you, tailored to your individual needs.
        </MiddleSizeText>
      </Section>
      <Section columns={2}>
        <SideTextWrapper>
          <SectionTitle>
            personal Branding
            <br />
            Photography
          </SectionTitle>
          <Text>
            I offer photo sessions for sustainable brands & soulful creatives:
            therapists, dancers, yogis, etc. to help them show up with
            confidence and connect with their ideal clients.
          </Text>
          <CTAButton>
            <Link to="/personal-branding">Check my Portfolio</Link>
          </CTAButton>
        </SideTextWrapper>
        <SideImage>
          <Img fluid={data.side1.childImageSharp.fluid} />
        </SideImage>
      </Section>
      <Section columns={2}>
        <SideImage>
          <Img fluid={data.side2.childImageSharp.fluid} />
        </SideImage>
        <SideTextWrapper>
          <SectionTitle>
            Documentary
            <br />
            Photography
          </SectionTitle>
          <Text>
            I photograph courses & workshops related to self-development and
            various forms of artistic expression: dance classes, theatre
            workshops, yoga retreats, etc.
          </Text>
          <CTAButton>
            <Link to="/documentary">Check my Portfolio</Link>
          </CTAButton>
        </SideTextWrapper>
      </Section>
      <SectionWithHeader columns={3}>
        <SectionTitle>What do you get ?</SectionTitle>
        <CardImg>
          <Img fluid={data.icon1.childImageSharp.fluid} />
        </CardImg>
        <CardImg>
          <Img fluid={data.icon2.childImageSharp.fluid} />
        </CardImg>
        <CardImg>
          <Img fluid={data.icon3.childImageSharp.fluid} />
        </CardImg>
        <CardText>
          Plenty of photos for your social media and website that transmit the
          essence of your service and represent your unique style
        </CardText>
        <CardText>
          My guidance through the whole process so that you can feel relaxed in
          front of the camera and fully express yourself
        </CardText>
        <CardText>Work in a friendly, relaxed atmosphere</CardText>
      </SectionWithHeader>
      <HeroSection
        ref={letsMeetRef}
        columns={1}
        style={{ marginBottom: "4em" }}
      >
        {/* <div className="bg-img">
          <Img fluid={data.hero2.childImageSharp.fluid} />
        </div> */}
        <div className="front-img">
          <Img fluid={data.hero2.childImageSharp.fluid} />
        </div>
        <div style={{ height: "60vh" }} />
      </HeroSection>
      <Section columns={1} style={{ marginBottom: "4em" }}>
        <Text>
          Hi, I'm Dominika. I'm a tireless seeker of authenticity and true
          stories. My curiosity about the world and different cultures made me
          graduate of Asian studies at the Jagiellonian University in my
          hometown Krakow. My spirit of adventure pushed me to travel a lot. I
          was meeting wonderful people on my way who were sharing their stories
          with me. I really wanted to document them, and that's how I discovered
          photography.
          <br />
          <br />
          In 2018, I settled down in Barcelona. In this Mediterranean city, I
          started my own business as a documentary & personal branding
          photographer. My mission is to tell your story in a captivating and
          authentic way.
        </Text>
      </Section>
      <Section columns={1}>
        <MiddleSizeText style={{ fontFamily: "monument-bold" }}>
          ARE YOU CONSIDERING A PHOTO SESSION?
        </MiddleSizeText>
        <MiddleSizeText padding="2.5em">
          I invite you to a<strong> free</strong>, completely{" "}
          <strong>non-binding</strong>, online meeting during which we will get
          to know each other and I will answer all your questions.
        </MiddleSizeText>
        <CTAButton>
          <a href="https://calendly.com/barskaphotostories">
            BOOK A FREE MEETING
          </a>
        </CTAButton>
      </Section>
      <SectionWithHeader columns={3}>
        <SectionTitle>Testimonials</SectionTitle>
        <Testimonial>
          I did an amazing magical photoshoot with Barska Photo Stories and she
          captured my ENERGY, ESSENCE, AND PERSONALITY beautifully! She gave me
          space to fully express myself and guided me to be more of who I truly
          am.
          <br />
          If you are looking for a photographer to capture your true essence,
          then Dominika is the one! She is super sweet, an amazing listener, and
          open-hearted. I had so much fun with her and I can't wait to do this
          again!
          <br />
          <br />
          Maria Guerrero
          <br />- Self Love Coach
        </Testimonial>

        <Testimonial padding="1.25em">
          Working with Dominika was a real pleasure. I was satisfied with the
          outcome but also the way she understands you as an artist. What is
          really unique is the process before the photoshoot when she takes time
          to get to know you. She sees right through your needs and includes
          them in the pictures. I highly recommend Dominika as a brand
          photographer.
          <br />
          <br />
          Kasia Nik
          <br />- Actress
        </Testimonial>

        <Testimonial padding="1.25em">
          It was an amazing time with Dominika Barska. She can feel the people
          and she knows how to make a good foto. She can discover the character
          of the person. Work with her is a lovely journey. I want more.
          <br />
          <br />
          Anna Gawłowska
          <br />- Glass Artist
        </Testimonial>
      </SectionWithHeader>
      <Section columns={2}>
        <SideTextWrapper>
          <SectionTitle>How does it work?</SectionTitle>
          <CTAButton>
            <a href="https://calendly.com/barskaphotostories">
              BOOK A FREE MEETING
            </a>
          </CTAButton>
        </SideTextWrapper>
        <ColorAccordeon R={240} G={200} B={120} nItems={6}>
          <Text padding="1.25em">You book a free online meeting</Text>
          <Text padding="1.25em">
            I send you a brand questionnaire that will help me understand better
            the service you offer
          </Text>
          <Text padding="1.25em">
            We have a brainstorming session via Zoom or in-person and create a
            plan based on our common vision
          </Text>
          <Text padding="1.25em">
            We do our photoshoot tailored to your individual needs
            <span role="img" aria-label="camera">
              📷
            </span>
          </Text>
          <Text padding="1.25em">
            I edit and send you the pictures (up to 1 week later)
          </Text>
          <Text padding="1.25em">
            I'm still here for you if you need me
            <span role="img" aria-label="smile">
              😉
            </span>
          </Text>
        </ColorAccordeon>
      </Section>
      <SectionWithHeader columns={1}>
        <SectionTitle>
          Do you have any questions?
          <br />
          Drop me a message!
        </SectionTitle>
        <Contact />
      </SectionWithHeader>
    </Layout>
  )
}

export const query = graphql`
  query {
    side1: file(relativePath: { eq: "photography_landing/side1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    side2: file(relativePath: { eq: "photography_landing/side2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hero1: file(relativePath: { eq: "photography_landing/hero1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hero2: file(relativePath: { eq: "photography_landing/hero2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    icon1: file(relativePath: { eq: "photography_landing/icon1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    icon2: file(relativePath: { eq: "photography_landing/icon2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    icon3: file(relativePath: { eq: "photography_landing/icon3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
